<template>
  <div class="container-page big">
    <div class="title-block-top title-block-top--full">
      <div class="title-block-top__first title-block-top__first--full">
        <h2 class="title title--h2">Межправительственные комиссии (МПК) и&nbsp;рабочие группы (рг)</h2>
      </div>
      <div class="title-block-top__second title-block-top__second--full">
        <div class="commision-button-container">
          <div class="commision-button-container__left">
            <ButtonComponent @click="addCardMPK()" mod="gradient-bg" class="button-redesign--mpk-commissions"
              >Создать МПК</ButtonComponent
            >
            <ButtonComponent @click="addCardRG()" mod="gradient-bg" class="button-redesign--mpk-commissions"
              >Создать рабочую группу (РГ)</ButtonComponent
            >
          </div>
          <div class="commision-button-container__right">
            <ButtonComponent @click="exportExcel()" mod="gradient-bg" class="button-redesign--mpk-commissions"
              >Экспорт</ButtonComponent
            >
          </div>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="commision-mpk__table table-container-base">
      <table>
        <thead>
          <tr>
            <th class="column-icon" v-if="canEdit"></th>
            <th class="column-name-mpk">Полное название комиссии МПК/РГ</th>
            <th class="column-country">Страна</th>
            <th class="column-chairman-RF">Председатель Российской части МПК / Российской части РГ</th>
            <th class="column-chairman-foreigh">Председатель иностранной части МПК/иностранной части РГ</th>
            <th class="column-deputy-chairman-RF">Заместитель Председателя Российской части МПК/РГ</th>
            <th class="column-executive-secretary-RF">Ответственный секретарь российской части МПК/РГ</th>
            <th class="column-meeting">Встреча сопредседателей МПК/РГ</th>
            <th class="column-session">Заседание МПК (заседание РГ)</th>
          </tr>
          <tr>
            <th class="column-icon" v-if="canEdit"></th>
            <th class="column-name-mpk">
              <ComponentInput
                placeholder="Название"
                mod="filter"
                v-model="filter.name"
                clearButton
                @onClear="clearFilter('name')"
              />
            </th>
            <th class="column-country">
              <SelectMultiComponent
                v-model="filter.countryId"
                :options="computedCountryList"
                valueProp="value"
                placeholder="Страна"
                classSelect="multiselect--rectangular multiselect--filter"
                class="visible-clear"
              />
            </th>
            <th class="column-chairman-RF">
              <ComponentInput
                placeholder="Председатель"
                mod="filter"
                v-model="filter.chairman"
                clearButton
                @onClear="clearFilter('chairman')"
              />
            </th>
            <th class="column-chairman-foreigh">
              <ComponentInput
                placeholder="Председатель"
                mod="filter"
                v-model="filter.chairmanForeign"
                clearButton
                @onClear="clearFilter('chairmanForeign')"
              />
            </th>
            <th class="column-deputy-chairman-RF">
              <ComponentInput
                placeholder="Заместитель"
                mod="filter"
                v-model="filter.coChairman"
                clearButton
                @onClear="clearFilter('coChairman')"
              />
            </th>
            <th class="column-executive-secretary-RF">
              <ComponentInput
                placeholder="Секретарь"
                mod="filter"
                v-model="filter.secretary"
                clearButton
                @onClear="clearFilter('secretary')"
              />
            </th>
            <th class="column-meeting">
              <dateComponent
                v-model="filter.lastEventFrom"
                modLabel="big"
                clearable
                :dateIcon="false"
                modClass="filter"
              />
              <dateComponent
                v-model="filter.lastEventTo"
                modLabel="big"
                clearable
                :dateIcon="false"
                modClass="filter"
              />
            </th>
            <th class="column-session">
              <dateComponent
                v-model="filter.lastEventZasedFrom"
                modLabel="big"
                clearable
                :dateIcon="false"
                modClass="filter"
              />
              <dateComponent
                v-model="filter.lastEventZasedTo"
                modLabel="big"
                clearable
                :dateIcon="false"
                modClass="filter"
              />
            </th>
          </tr>
        </thead>
        <TableBody :itemsBody="itemsTable" :isLoading="isLoading">
          <tr
            v-for="item in itemsTable"
            :key="item.id"
            @dblclick="editCard(item)"
            :class="[{ 'tr-commission': !item.parentId }, 'hover-tr']"
          >
            <td v-if="canEdit" class="column-icon">
              <div class="wrap-icon">
                <IconComponent class="icon--full-svg" @click="editCard(item)" name="edit" />
                <IconComponent class="icon--full-svg delete" @click="deleteCard(item.id)" name="delete-not-fill" />
              </div>
            </td>
            <td class="column-name-mpk">{{ item.name }}</td>
            <td class="column-country">{{ item.country.name }}</td>
            <td class="column-chairman-RF">
              <template v-if="item.chairman">
                <p class="name">{{ item.chairman.name }}</p>
                {{ item.chairman.position }}
              </template>
              <template v-else> (уточняется) </template>
            </td>
            <td class="column-chairman-foreigh">
              <template v-if="item.chairmanForeign">
                <p class="name">{{ item.chairmanForeign.name }}</p>
                {{ item.chairmanForeign.position }}
              </template>
              <template v-else> (уточняется) </template>
            </td>
            <td class="column-deputy-chairman-RF">
              <template v-if="!item.parentId">
                <template v-if="item.coChairman">
                  <p class="name">{{ item.coChairman.name }}</p>
                  {{ item.coChairman.position }}
                </template>
                <template v-else> (уточняется) </template>
              </template>
            </td>
            <td class="column-executive-secretary-RF">
              <template v-if="!item.parentId">
                <template v-if="item.secretary">
                  <p class="name">{{ item.secretary.name }}</p>
                  {{ item.secretary.position }} {{ item.secretary.phone }}
                </template>
                <template v-else> (уточняется) </template>
              </template>
            </td>
            <td class="column-meeting">
              <template v-if="item.lastEvent">
                <p class="date">{{ $momentFormat(item.lastEvent.eventFirstDate, 'DD MMMM YYYY') }}</p>
                {{ item.lastEvent.place }}
              </template>
              <template v-else> (уточняется) </template>
            </td>
            <td class="column-session">
              <template v-if="item.lastEventZased">
                <p class="date">{{ $momentFormat(item.lastEventZased.eventFirstDate, 'DD MMMM YYYY') }}</p>
                {{ item.lastEventZased.place }}
              </template>
              <template v-else> (уточняется) </template>
            </td>
          </tr>
        </TableBody>
      </table>
    </div>

    <template v-if="page.pagesTotal > 1">
      <PaginationRedesigned
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event)"
        @more="onPageMore($event)"
      />
    </template>
  </div>
</template>

<script>
  import { debounce } from 'lodash';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import TableBody from '@/common/components/redesigned/TableBody';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import API from '../api/commission';

  export default {
    name: 'Commissions',
    components: {
      IconComponent,
      ButtonComponent,
      PaginationRedesigned,
      ComponentInput,
      SelectMultiComponent,
      dateComponent,
      TableBody,
    },
    mixins: [security, filtermanager],
    data() {
      return {
        type: this.$route.params.type === 'rg' ? 'rg' : 'mp',
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          name: '',
          countryId: this?.$route.query?.countryId ?? '',
          chairman: '',
          chairmanForeign: '',
          coChairman: '',
          secretary: '',
          lastEventFrom: null,
          lastEventTo: null,
          lastEventZasedFrom: null,
          lastEventZasedTo: null,
          nextEventZasedFrom: null,
          nextEventZasedTo: null,
        },
        mpkCountryList: [{ value: 0, label: Constants.emptySelectOptionText }],
        page: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          pagesTotal: 0,
        },
        itemsTable: [],
        canEdit: false,
        isLoading: false,
      };
    },
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.MpkCommissionAccess);
      this.checkPermission(Constants.Permissions.MpkCommissionEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.loadFilter(this.type).then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        Utils.loadMultiSelectOptions('countryList', this.mpkCountryList, true);
        this.loadPage();
      });
    },
    methods: {
      loadPage() {
        this.isLoading = true;
        API.search({
          ...this.filter,
          ...this.page,
        })
          .then((response) => {
            this.itemsTable = response.data.items;
            this.page.pageNumber = response.data.pageNumber;
            this.page.pagesTotal = response.data.pagesTotal;
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
          });
      },

      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter, this.type);
        this.loadPage(false);
      },

      clearFilter(field) {
        if (Object.hasOwn(this.filter, field)) {
          this.filter[field] = null;
        }
      },
      exportExcel() {
        API.exportExcel(this.filter);
      },

      addCardMPK() {
        this.$router.push({ name: 'CommissionCard', params: { id: 0, type: 'mp', action: 'create' } });
      },

      addCardRG() {
        this.$router.push({ name: 'CommissionCard', params: { id: 0, type: 'rg', action: 'create' } });
      },

      editCard(item) {
        var _type = this.type;
        if (_type === 'mp' && item.parentId > 0) _type = 'rg';
        this.$router.push({
          name: 'CommissionCard',
          params: { id: item.id, type: _type, action: this.canEdit ? 'edit' : 'view' },
        });
      },

      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data
                    ? error.response.data
                    : 'Запись не удалена. Она используется в других реестрах.',
                  'error',
                );
              });
          }
        });
      },

      onPageChange(pageNumber) {
        this.page.pageNumber = pageNumber;
        this.loadPage();
      },

      onPageMore(pageNumber) {
        this.page.pageNumber = pageNumber;
        API.search({
          ...this.filter,
          ...this.page,
        })
          .then((response) => {
            this.itemsTable = this.itemsTable.concat(response.data.items);
          })
          .catch((error) => {
            console.error(error);
          });
      },

      debounceChangeValue: debounce(function () {
        this.changeFilter();
      }, 500),
    },
    computed: {
      // для правки отображения выбранного значения в `@vueform/multiselect`
      computedCountryList() {
        return [...this.mpkCountryList];
      },
    },
    watch: {
      filter: {
        handler() {
          this.page.pageNumber = 1;
          this.debounceChangeValue();
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  @import '@/assets/theme/default/table/table-base.scss';

  .commision-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__right {
      display: flex;
      flex-wrap: wrap;
    }

    @media (max-width: $mobile) {
      &__left,
      &__right {
        width: 100%;
      }

      &__filter {
        margin-top: 8px;
      }
    }
  }
</style>
