<template>
  <tbody v-if="itemsBody.length">
    <slot />
    <tr v-if="itemsBody.length < 4" class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
    <tr v-if="itemsBody.length < 3" class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
    <tr v-if="itemsBody.length < 2" class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
  </tbody>
  <tbody v-else-if="isLoading">
    <tr class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
    <tr class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
    <tr class="stub">
      <td class="text-center" colspan="11">Загрузка...</td>
    </tr>
    <tr class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
    <tr class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
  </tbody>
  <tbody v-else>
    <tr class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
    <tr class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
    <tr class="stub">
      <td class="text-center" colspan="11">Нет данных.</td>
    </tr>
    <tr class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
    <tr class="stub">
      <td class="text-center" colspan="11"></td>
    </tr>
  </tbody>
</template>

<script>
  export default {
    name: 'TableBody',
    props: {
      itemsBody: {
        type: Array,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';

  .stub {
    box-shadow: none !important;

    &::before,
    &::after {
      content: none;
    }
  }
</style>
